import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import AddIcon from '@material-ui/icons/Add';
import RichText from '~components/RichText';
import { Box } from '@material-ui/core';
import { generateUrlFriendlyIdFromTitle } from '~utils/url-helper';

const useStyles = makeStyles(theme => ({
  section: {
    position: 'relative',
    backgroundColor: props => props.background_color,
    paddingTop: '100px',
    scrollMarginTop: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '40px'
    }
  },
  root: {
    width: '100%',
    display: 'flex'
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontSize: '40px',
    fontWeight: '600',
    textAlign: 'center',
    margin: 'auto',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px'
    }
  },
  accordion: {
    borderRadius: '10px !important',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.10)',
    padding: '8px 15px 8px 15px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px'
    },
    '&:before': {
      backgroundColor: 'unset !important'
    }
  },
  accordionExpanIcon: {
    color: '#000000',
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  question: {
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  answer: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}));

const ArticleFaq = ({ section_title, background_color, faqs, containerSize }) => {
  const classes = useStyles({ background_color });

  return (
    <div className={classes.section} id={generateUrlFriendlyIdFromTitle(section_title.text)}>
      <div className={classes.root}>
        <Container maxWidth={containerSize}>
          <h2 className={classes.sectionTitle}>{section_title.text}</h2>
          <Box
            component="div"
            width="100%"
            display="flex"
            flexDirection="column"
            gridGap="13px"
            marginBottom="40px"
          >
            {faqs &&
              faqs.map((item, index) => (
                <Accordion key={item.topic_title.text} className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<AddIcon className={classes.accordionExpanIcon} />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                  >
                    <Typography className={classes.question}>{item.topic_title.text}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RichText html={item.topic_content.html} externalClassName={classes.answer} />
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Container>
      </div>
    </div>
  );
};

ArticleFaq.propTypes = {
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  background_color: PropTypes.string.isRequired,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      topic_title: PropTypes.string.isRequired,
      topic_content: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  containerSize: PropTypes.string
};

ArticleFaq.defaultProps = {
  containerSize: 'md'
};

export default ArticleFaq;
